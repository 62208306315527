@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Barlow";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121619;
}

/* Firefox */

.recharts-default-tooltip {
  background-color: #121619 !important;
  border-color: #21262a !important;
}

.mainbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;

  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-status {
  font-size: 14px !important;
  font-weight: 500;
  background-color: #21262a;
  border-radius: 0.65rem;
  margin-top: 5px;
  margin-right: 5px;
  padding-right: 1px;
  padding-left: 1px;
}

.calendarAboveInput .rdtPicker,
input {
  color: #e2e2e2;
  border-color: #828282;
  background-color: #21262a;
  bottom: 45px;
  outline-width: 0ch;
  left: 0px;
}

.rdtPicker .rdtActive {
  background-color: #8454eb !important;
}

.rdtPicker .rdtNew:hover {
  background-color: #828282 !important;
  color: #c2c2c2;
}

.rdtPicker .rdtDay:hover {
  background-color: #828282 !important;
}
.rdtPicker .rdtToday::before {
  border-bottom-color: #8454eb !important;
}

.calendarAboveInput,
input {
  color: #b2b2b2;
  border-color: #828282;
  background-color: #121619;
}

/* Toggle A */
input ~ .checked {
  transform: translateX(100%);
  background-color: #48bb78;
}

@keyframes custombounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

[data-placeholder]::after {
  content: " ";
  box-shadow: 0 0 50px 9px rgba(0, 0, 0);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  animation: load 1s infinite;
}
@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

.first-circle {
  animation-delay: 0.1s !important;
}
.second-circle {
  animation-delay: 0.2s !important;
}
.third-circle {
  animation-delay: 0.3s !important;
}


.landingbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.roadmapbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}

.collectionsbg {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0px 0px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body {
  margin: 0;
  height: 100%;
}

.ReactModalPortal {
  position: absolute;
  z-index: 100;
}

.ticket ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.ticket ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(200, 200, 200, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

canvas {
  width: 100%;
  position: fixed;
  display: block;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
}

#root {
  min-height: 100%;
  font-family: "Barlow";
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.polygonpath {
  stroke-dasharray: 2500;
  stroke-dashoffset: 2500;
  animation: dash 2s linear alternate infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
